import React from "react"

const clientdetails = () => {
  return (
    <section className="exclusive">
      <div className="container exclusive ">
        <div className="row">
          <div className="col-12 col-lg-12 text-center">
            <p className="story-text">
              We believe in enriching the brands with captivating digital
              marketing experience. We put the creative hats on for delivering
              the best marketing and promotional solutions to our clients. We
              create, we deliver and we succeed only because of the client’s
              trust in us. We work with close connection with our clients and we
              are determined towards establishing their brands and to invoke
              demand for their products. We pitch in bright and colorful ideas
              for the marketing campaigns of our clients so that they can reach
              out to a million hearts across the globe along with getting a
              competitive edge in the market.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default clientdetails
