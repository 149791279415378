import React from "react"

import clientone from "../../images/client/tanishq.jpg"
import clienttwo from "../../images/client/Rwd.jpg"
import clientthree from "../../images/client/hiranandani.jpg"
import clientfour from "../../images/client/bigbazaar.jpg"
import clientfive from "../../images/client/sumeru.jpg"
import clientsix from "../../images/client/Titan.jpg"
import clientseven from "../../images/client/Vip.jpg"
import clienteight from "../../images/client/Coffee day.jpg"
import hello from "../../images/client/hello fm.jpg"
import hinduja from "../../images/client/hinduja.jpg"
import blackberrys from "../../images/client/blackberrys.jpg"
import hap from "../../images/client/hap.jpg"
import Eta from "../../images/client/Eta star.jpg"
import manyavar from "../../images/client/manyavar.jpg"
import Indian from "../../images/client/Indian Express.jpg"
import akshaya from "../../images/client/akshaya.jpg"
import hikoki from "../../images/client/hikoki.jpg"
import ashok from "../../images/client/ashok leyland.jpg"
import reliance from "../../images/client/reliance.jpg"
import ruchi from "../../images/client/ruchi.jpg"
import kitchenji from "../../images/client/kitchenji.jpg"
import Sunbeam from "../../images/client/Sunbeam.jpg"
import ncscm from "../../images/client/ncscm.jpg"
import greaves from "../../images/client/greaves.jpg"
import oppo from "../../images/client/Oppo_logo_PNG.jpg"
import Trends from "../../images/client/Reliance-Trends-Logo-Vector.png"

const clientspage = () => {
  return (
    <section id="clients" className="section-bg exclusive">
      <div className="container" data-aos="fade-up">
        <div className="section-header"></div>

        <div
          className="row clients-wrap clearfix"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={clientone} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={clienttwo} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={clientthree} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={clientfour} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={clientfive} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={clientsix} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={clientseven} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={clienteight} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={hello} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={hinduja} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={blackberrys} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={hap} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={Eta} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={manyavar} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={Indian} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={akshaya} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={hikoki} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={ashok} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={reliance} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={ruchi} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={kitchenji} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={Sunbeam} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={ncscm} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={greaves} className="img-fluid" alt="" />
            </div>
          </div> 
          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={oppo} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img src={Trends} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default clientspage
