import React from "react"

const clientaboutpage = () => {
  return (
    <section>
      <div className="container pt-4">
        <div className="row">
          <div className="col-12 col-lg-12 text-center">
            <h4 className="heading fw-bold about-heading">Clients</h4>
            <p className="story-text py-4">
              We believe in nurturing creativity and technology to portray
              powerful brand stories and we strive towards making real
              connections with people. Appreciation from our clients is music to our ears and our biggest asset.
            </p>
            <p className="about-digital pt-4">
              Artistic and engaging graphic designs with a touch of empathy
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default clientaboutpage
