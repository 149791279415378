import React from "react"

import Layout from "../components/layout"
import ClientsPage from "../components/clients/index"
import Seo from "../components/seo"

const clientsPage = () => (
  <Layout>
    <Seo title="Our Clients - Mass Media Creatives" desc="" keywords="" />
    <ClientsPage />
  </Layout>
)

export default clientsPage
